<template>
    <vs-popup fullscreen :title="$t('emr.appt.prescription') " :active.sync="popupActive" @close="close">
        <div id="rx" class="p-4 whitespace-no-wrap" style="font-family: 'Microsoft YaHei'; background-color: #eee; width: 900px;" v-if="RXData">
            <div class="text-center">
                <span class="text-lg">{{ RXData.partner_name }}</span>
            </div>
            <div class="flex justify-around">
                <span class="text-lg">{{ RXData.partner_addr }}</span>
                <span class="text-lg">{{ $t('emr.appt.phone') }}{{ RXData.partner_phone }}</span>
            </div>
            <vs-divider />
            <div class="text-center">
                <span class="text-lg">{{ $t('emr.appt.prescription') }}</span>
            </div>
            <div class="flex">
                <div class="w-1/4">
                    <span>{{ $t('emr.appt.mr_id') }}{{ RXData.mr_id }}</span>
                </div>
                <div class="w-1/4">
                    <span>{{ $t('emr.appt.name') }}{{ RXData.pname }}</span>
                </div>
                <div class="w-1/4">
                    <span>{{ $t('emr.appt.sex') }}{{ getSex(RXData.psex) }}</span>
                </div>
                <div class="w-1/4">
                    <span>{{ $t('emr.appt.birth') }}{{ getAge(RXData.pbirth) }}</span>
                </div>
            </div>
            <div class="flex justify-between">
                <div class="w-1/4">
                    <span>{{ $t('emr.appt.did') }}{{ RXData.did }}</span>
                </div>
                <div class="w-1/4">
                    <span>{{ $t('emr.appt.dname') }}{{ RXData.dname }}</span>
                </div>
                <div class="w-1/4">
                    <span>{{ $t('emr.appt.date') }}{{ RXData.date }}</span>
                </div>
                <div class="w-1/4">
                    <span>{{ $t('emr.appt.num') }}{{ RXData.num }}</span>
                </div>
            </div>
            <div class="flex justify-between">
                <!-- <div class="w-1/4">
                    <span>健保卡就醫序號：</span>
                </div> -->
                <div class="w-1/4">
                    <span>{{ $t('emr.appt.CaseType') }}</span>
                </div>
                <div class="w-1/4">
                    <span>{{ $t('emr.appt.division') }}</span>
                </div>
                <div class="w-1/4">
                    <span>{{ geRxType(RXData.rx_type) }}</span>
                </div>
            </div>
            <div class="flex">
                <iframe id="rx_canvas" :src="imgSrc" frameborder="0" width="60px" height="60px" v-if="imgSrc"/>
                <div class="ml-8">
                    <div class="flex" v-for="(item, index) in RXData.icd" :key="index">
                        <div class="w-24">
                            <span>{{ item.disease_code }}</span>
                        </div>
                        <div>
                            <span>{{ item.ename }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="flex">
                    <div class="w-32">
                        <span>{{ $t('emr.appt.code_ICD') }}</span>
                    </div>
                    <div class="w-2/5">
                        <span>{{ $t('emr.appt.name_ICD') }}</span>
                    </div>
                    <div class="w-16">
                        <span>{{ $t('emr.appt.daily') }}</span>
                    </div>
                    <div class="w-16">
                        <span>{{ $t('emr.appt.unit_p') }}</span>
                    </div>
                    
                    <div class="w-16">
                        <span>{{ $t('emr.appt.frequency') }}</span>
                    </div>

                    <div class="w-16">
                        <span>{{ $t('emr.appt.route_p') }}</span>
                    </div>
                    <div class="w-16">
                        <span>{{ $t('emr.appt.days_p') }}</span>
                    </div>
                    <div class="w-16">
                        <span>{{ $t('emr.appt.total_p') }}</span>
                    </div>
                </div>
                <div class="flex" v-for="(item, index) in RXData.med" :key="index">
                    <div class="w-32">
                        <span>{{ item.med_info_id }}</span>
                    </div>
                    <div class="w-2/5">
                        <span>{{ item.medical_en_name }}</span>
                    </div>
                    <div class="w-16">
                        <span>{{ item.dose }}</span>
                    </div>
                    <div class="w-16">
                        <span>{{ item.unit }}</span>
                    </div>
                    <div class="w-16">
                        <span>{{ item.med_freq_id }}</span>
                    </div>
                    <div class="w-16">
                        <span>{{ item.med_route_id }}</span>
                    </div>
                    <div class="w-16">
                        <span>{{ item.days }}</span>
                    </div>
                    <div class="w-16">
                        <span>{{ item.total_dose }}</span>
                    </div>
                </div>
            </div>
        </div>
    </vs-popup>
</template>
<script>
import { getRXData } from '@/api/user'
export default {
    props: {
        RXPopupActive: {
            type: Boolean,
            required: true,
        },
        mr_id: {
            type: Number,
            required: false
        },
    },
    data() {
        return {
            RXData: null,
            imgSrc: '',
        }
    },
    watch: {
        popupActive(v){
            if(!v) return
            this.viewRX()
        }
    },
    computed: {
        popupActive: {
            get() {
                return this.RXPopupActive
            },
            set(v) {
                return v
            },
        },
    },
    methods: {
        // 顯示處方簽
        viewRX(){
            this.$vs.loading()

            // 取QRCode內容
            this.$store.dispatch('getRxString', {"mr_id": this.mr_id}).then(res=>{
                this.imgSrc = this.QRCode(res.data.data, 60, 60)
            })
            
            let payload = {
                "mr_id": this.mr_id
            }
            getRXData(payload).then((res) => {
                if(res.data.status=='OK'){
                    this.RXData = res.data.data
                    this.$vs.loading.close()
                }
            })
        },
        // 關閉popup
        close(){
            this.$emit("closePopup")
        },
        // 取性別
        getSex(val){
            const statusMap = {
                0: '-',
                1: this.$t('monitor.man'),
                2: this.$t('monitor.woman')
            }
            return statusMap[val]
        },
        // 取年齡
        getAge(val){
            if (val === '0000-00-00' || !val) {
                return '--'
            } else {
                const ageMS = Date.parse(Date()) - Date.parse(val)
                const age = new Date()
                age.setTime(ageMS)
                const ageYear = age.getFullYear() - 1970
                return ageYear
            }
        },

        // 取處方籤類型
        geRxType(val){
            const statusMap = {
                0: this.$t('emr.appt.prescription'),
                1: this.$t('emr.appt.prescription'),
                2: this.$t('emr.appt.continuousPrescription')
            }
            return statusMap[val]
        },
        // 產生QRCode
        QRCode(content, width = 120, height = 120){
            content = encodeURIComponent(content);
            return 'http://chart.apis.google.com/chart?cht=qr&chl=' + content + '&chs=' + width + 'x' + height;
        },
        // 下載pdf
        dlPdf(){
            this.$getPdf("rx","test");
        },
    }
}
</script>